<template>
  <div class="mt-12">

    <Datatable
      :config="datatableConfig"
      :elements="campaigns"
      :sst="true"
      :stripe="true"
      :pagination="pagination"
      pagination-position="top"
      :show-refresh-button="true"
      @refresh="handleRefresh"
      @filter="handleFilter"
      @action="handleAction"
      :delete-dialog="{
        title: this.$t('apps.campaigns.section_history_delete_dialog_title'),
        text: this.$t('apps.campaigns.section_history_delete_dialog_text'),
      }"
    />
  </div>
</template>

<script>
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import CampaignsService from '@/modules/Apps/Services/CampaignsService'
import {fromDBToTimezone} from "@/modules/Shared/Helpers/dateHelper";
import {mapGetters} from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications";
import {DateTime, Duration} from "luxon";
import AppsService from "@/modules/Apps/Services/AppsService";
import {truncate} from "@/modules/Shared/Helpers/stringHelper";

export default {
    name: 'campaigns-history-datatable',
    components: {
      Datatable
    },
    mixins: [notifications],
    props: {
      hasNotificationsAllowed: {
          type: Boolean,
      }     
    }, 
    data () {
      return {
        applicationUuid: '',
        campaigns: [],
        resorts: [],
        pagination: {},
        baseFilters: [],
        currentFilters: [],
        currentPage: 1
      }
    },
    async created() {
      this.applicationUuid = this.$route.params.uuid
      this.baseFilters = [
        {
          field: 'application_uuid',
          value: this.applicationUuid
        },
        {
          field: 'visible',
          value: 1
        }
      ]
      this.currentFilters = this.baseFilters

      await this.getResorts()
      await this.getCampaigns()
    },
    computed: {
      ...mapGetters(['AppActiveUser']),
      datatableConfig() {

        let configTable = [
          { 'field': 'image', head: this.$t('apps.campaigns.section_history_table_image'), type: 'smallimage', 'searchable': false, 'sortable': false },
          { 'field': 'title', head: this.$t('apps.campaigns.section_history_table_title'), 'searchable': true, 'sortable': false },
          { 'field': 'message', head: this.$t('apps.campaigns.section_history_table_message'), 'searchable': true, 'sortable': false },
          { 'field': 'language', head: this.$t('apps.campaigns.section_history_table_language'), 'searchable': false, 'sortable': false },
          { 'field': 'segmentation', head: this.$t('apps.campaigns.section_history_table_segmentation'), 'searchable': false, 'sortable': false },
          // { 'field': 'challenge', head: 'challenge', 'searchable': false, 'sortable': false },
          { 'field': 'sent', head: this.$t('apps.campaigns.section_history_table_sent'), 'searchable': false, 'sortable': false },
          { 'field': 'open_rate', head: this.$t('apps.campaigns.section_history_table_open_rate'), 'searchable': false, 'sortable': false },
          { 'field': 'to_be_delivered_at', head: this.$t('apps.campaigns.section_history_table_schedule_at'), 'searchable': false, 'sortable': true },
          { 'field': 'expires_at', head: this.$t('apps.campaigns.section_history_table_expiration_date'), 'searchable': false, 'sortable': false },
          { 'field': 'status', type: 'html', head: this.$t('apps.campaigns.section_history_table_status'), 'searchable': false, 'sortable': false },
          { 'field': 'actions', type: 'actions', head: this.$t('apps.campaigns.section_history_table_actions'), 'searchable': false, 'sortable': false }
        ]
        if (this.hasNotificationsAllowed) {
          configTable.splice(1, 0, { 'field': 'category', head: this.$t('apps.campaigns.section_history_table_category'), 'searchable': false, 'sortable': false });
        }

        return configTable
      },
    },
    methods: {
      async getCampaigns() {
        const campaignsData = await CampaignsService.getCampaigns(this.currentFilters, this.currentPage)
        this.pagination = {
          per_page: campaignsData.per_page,
          total: campaignsData.total
        }

        this.campaigns = campaignsData.data.map(this.campaignToTableRow)
      },
      async getResorts() {
        this.resorts = await AppsService.getResorts(this.$route.params.uuid)
      },
      async handleFilter({page, filters}) {
        this.currentFilters = [...this.baseFilters, ...filters]
        this.currentPage = page;

        await this.getCampaigns()
      },
      async handleRefresh() {
        await this.getCampaigns()
      },
      campaignToTableRow(campaign) {
        return {
          uuid: campaign.uuid,
          status: this.campaignStatus(campaign),
          category: campaign.category ? campaign.category.reference : null,
          image: campaign.content.image,
          title: campaign.content.title,
          message: campaign.content.message,
          segmentation: this.campaignSegmentation(campaign.options),
          // challenge: campaign.content.type === 'challenge' ? campaign.challenge.name : null,
          language: campaign.options.language,
          sent: campaign.statistics.messages_sent,
          open_rate: this.campaignOpenRate(campaign),
          to_be_delivered_at: fromDBToTimezone(campaign.to_be_delivered_at, this.AppActiveUser.timezone).toLocaleString(DateTime.DATETIME_SHORT),
          expires_at: fromDBToTimezone(campaign.to_be_delivered_at, this.AppActiveUser.timezone).plus(Duration.fromObject({ days: Math.floor(campaign.ttl / 24), hour: campaign.ttl % 24 })).toLocaleString(DateTime.DATETIME_SHORT),
          actions: this.campaignActions(campaign),
        }
      },
      campaignSegmentation(options) {
        if (options.installation_uuids) {
          return this.$t('apps.campaigns.section_history_table_segmentation_selected_users')
        }

        let segmentation = []
        if (options.language) {
          segmentation.push(options.language)
        }
        if (options.only_registered) {
          segmentation.push(this.$t('apps.campaigns.section_history_table_segmentation_only_registered'))
        } else if (options.only_unregistered) {
          segmentation.push(this.$t('apps.campaigns.section_history_table_segmentation_only_unregistered'))
        } else if (options.favorite_resort_uuid) {
          const resort = this.resorts.find(resort => resort.uuid === options.favorite_resort_uuid)
          segmentation.push(truncate(resort.name, 15))
        } else {
          segmentation.push(this.$t('apps.campaigns.section_history_table_segmentation_all_users'))
        }

        return segmentation.join(' / ')
      },
      campaignStatus(campaign) {
          switch (campaign.status) {
              case 1: return `<div class="border border-warning text-warning py-2 px-4 rounded-full">${this.$t('apps.campaigns.section_history_status_programmed')}</div>`
              case 2: return `<div class="border border-warning text-warning py-2 px-4 rounded-full">${this.$t('apps.campaigns.section_history_status_sending')}</div>`
              case 3: return `<div class="border border-success text-success py-2 px-4 rounded-full">${this.$t('apps.campaigns.section_history_status_sent')}</div>`
              case 9: return `<div class="border border-danger text-danger py-2 px-4 rounded-full">${this.$t('apps.campaigns.section_history_status_failed')}</div>`
          }
      },
      campaignOpenRate(campaign) {
          if (!campaign.statistics.messages_sent) {
              return '0%'
          }

          const rate = Math.round(campaign.statistics.messages_read / campaign.statistics.messages_sent * 10000) / 100
          return `${rate}%`
      },
      campaignActions(campaign) {
          return campaign.status === 1
            ? ['clone', 'edit', 'delete']
            : ['clone']
      },
      async handleAction(eventType, uuid, __element) {
        switch (eventType) {
          case 'clone': {
            this.$emit('clone', uuid)
            break
          }
          case 'edit': {
            const path = `/apps/${this.applicationUuid}/campaigns/${uuid}/edit`
            if (this.$route.path !== path) await this.$router.push(path)
            break
          }
          case 'delete': {
            await CampaignsService.deleteCampaign(uuid)
            this.campaigns = this.campaigns.filter(campaign => campaign.uuid !== uuid)
            this.notifyError('Push deleted', this.$t('apps.campaigns.section_history_notification_delete_success'))
            break
          }
        }
      },
    }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: " flex flex-row mt-5" }, [
        _c("header", { staticClass: "w-1/2 " }, [
          _c("h3", { staticClass: "text-xl font-medium mt-3 text-black" }, [
            _vm._v(" " + _vm._s(_vm.$t("apps.campaigns.list.title")))
          ])
        ]),
        _c(
          "div",
          { staticClass: "w-1/2  text-right" },
          [
            _c(
              "vs-button",
              {
                staticClass: "rounded-lg xs:w-full sm:w-auto",
                on: {
                  click: function($event) {
                    return _vm.createNew()
                  }
                }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("apps.campaigns.form.createTitle")) + " "
                )
              ]
            )
          ],
          1
        )
      ]),
      _c("Datatable", {
        attrs: {
          config: _vm.datatableConfig,
          elements: _vm.campaignCategories,
          stripe: true,
          sst: true,
          draggable: true,
          draggableHeadTitle: _vm.draggableHeadTitle,
          deleteDialog: {
            title: _vm.$t("shops.delete"),
            text: _vm.$t("apps.campaings.delete_category_dialog")
          }
        },
        on: {
          filter: _vm.handleFilter,
          sort: _vm.sort,
          action: _vm.handleAction
        }
      }),
      _vm.isModalOpen
        ? _c(
            "div",
            [
              _c("CreateOrEditCategoryForm", {
                attrs: {
                  selectedCategory: _vm.selectedCategory,
                  languages: _vm.languages,
                  isNew: _vm.isNew,
                  aplicationUuid: _vm.aplicationUuid,
                  isModalOpen: _vm.isModalOpen
                },
                on: {
                  modalToFalse: function($event) {
                    return _vm.modalToFalse()
                  },
                  updateList: function($event) {
                    return _vm.updateList()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_data_fetched
    ? _c(
        "div",
        [
          _c(
            "vs-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            [
              _c(
                "vs-tab",
                { attrs: { label: this.$t("apps.campaigns.tab_new_push") } },
                [
                  _c(
                    "div",
                    [
                      _c("CampaignForm", {
                        attrs: {
                          hasDRMCampaignCategoriesAllowed:
                            _vm.hasDRMCampaignCategoriesAllowed,
                          hasNotificationsAllowed: _vm.hasNotificationsAllowed,
                          isSkiApp: _vm.isSkiApp
                        },
                        on: {
                          "campaign-scheduled": _vm.handleCampaignScheduled
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm.hasNotificationsAllowed && _vm.hasDRMCampaignCategoriesAllowed
                ? _c(
                    "vs-tab",
                    { attrs: { label: this.$t("apps.campaigns.tab_list") } },
                    [_c("div", [_c("campaignList")], 1)]
                  )
                : _vm._e(),
              _c(
                "vs-tab",
                { attrs: { label: this.$t("apps.campaigns.tab_history") } },
                [
                  _c("CampaignsHistoryDatatable", {
                    attrs: {
                      hasNotificationsAllowed: _vm.hasNotificationsAllowed
                    },
                    on: { clone: _vm.handleClone }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-12" },
    [
      _c("Datatable", {
        attrs: {
          config: _vm.datatableConfig,
          elements: _vm.campaigns,
          sst: true,
          stripe: true,
          pagination: _vm.pagination,
          "pagination-position": "top",
          "show-refresh-button": true,
          "delete-dialog": {
            title: this.$t(
              "apps.campaigns.section_history_delete_dialog_title"
            ),
            text: this.$t("apps.campaigns.section_history_delete_dialog_text")
          }
        },
        on: {
          refresh: _vm.handleRefresh,
          filter: _vm.handleFilter,
          action: _vm.handleAction
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div  v-if="is_data_fetched">
      <vs-tabs v-model="activeTab">
        <vs-tab :label="this.$t('apps.campaigns.tab_new_push')">
          <div>
            <CampaignForm @campaign-scheduled="handleCampaignScheduled"
                :hasDRMCampaignCategoriesAllowed="hasDRMCampaignCategoriesAllowed"
                :hasNotificationsAllowed="hasNotificationsAllowed"
                :isSkiApp="isSkiApp"
            />
          </div>
        </vs-tab>
        <vs-tab v-if="hasNotificationsAllowed && hasDRMCampaignCategoriesAllowed" :label="this.$t('apps.campaigns.tab_list')">
          <div>
            <campaignList></campaignList>
          </div>
        </vs-tab>
        <vs-tab :label="this.$t('apps.campaigns.tab_history')">
          <CampaignsHistoryDatatable @clone="handleClone"
            :hasNotificationsAllowed="hasNotificationsAllowed"
          />
        </vs-tab>
      </vs-tabs>
    </div>
</template>

<script>

import CampaignsHistoryDatatable from '@/modules/Apps/Components/campaigns/CampaignsHistoryDatatable'
import CampaignForm from '@/modules/Apps/Components/campaigns/form/CampaignForm'
import CampaignList from '@/modules/Apps/Components/campaigns/CampaignList'
import AppsService from "@/modules/Apps/Services/AppsService";
import CampaignsService from "@/modules/Apps/Services/CampaignsService";
import {mapActions} from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications";
import {mapGetters} from 'vuex';
export default {
    name: 'campaigns',
    components: {
        CampaignsHistoryDatatable,
        CampaignForm,
        CampaignList
    },
    mixins: [notifications],
    data: () => ({
      activeTab: 0,
      hasNotificationsAllowed: false,
      hasDRMCampaignCategoriesAllowed: false,
      isSkiApp: false,
      is_data_fetched: false,
    }),
    computed: {
        ...mapGetters( 'auth',['drm' ]),
    },

    async created() {
      this.$vs.loading()

      let appUuid = this.$route.params.uuid;
      let app = this.drm.apps.find(function(o){return o.uuid == appUuid})
      this.hasDRMCampaignCategoriesAllowed = app.drm.includes('campaign_categories');

      let basicInfo = await this.loadBasicInfo()

      this.hasNotificationsAllowed = Boolean(basicInfo.data.object.features.find(({ key }) => key === "show_notifications_preferences"))
      this.isSkiApp = Boolean(basicInfo.data.object.features.find(({ key }) => key === "is_ski_app"))

      this.is_data_fetched = true;
      this.$vs.loading.close();

      if (this.$route.query.section === 'history') {
        this.activeTab = 2
        await this.$router.replace({'query': null});
      }

      await this.initEmptyCampaignForm()
    },

    methods: {
      ...mapActions('apps', ['campaignToCloneForm']),
      async initEmptyCampaignForm() {
        this.$store.commit('apps/RESET_CAMPAIGN')
        await this.loadLanguages()
      },
      async loadLanguages() {
        const languages = await AppsService.getLanguages(this.$route.params.uuid)
        this.$store.commit('apps/SET_LANGUAGES', languages)
      },
      async loadBasicInfo() {
        return await AppsService.basicAppInfo(this.$route.params.uuid)
      },
      async handleCampaignScheduled() {
        this.activeTab = 2
        this.notifySuccess(this.$t('apps.campaigns.scheduled_notification_title'), this.$t('apps.campaigns.scheduled_notification_body'))
        await this.initEmptyCampaignForm()
      },
      async handleClone(campaignUuid) {
        await this.loadLanguages()
        const campaign = await CampaignsService.getCampaign(campaignUuid)
        await this.campaignToCloneForm(campaign)
        this.activeTab = 0
      }
    }
}
</script>

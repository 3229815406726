<template>
    <div>
        <div class=" flex flex-row mt-5">
            <header class="w-1/2 ">
                <h3 class="text-xl font-medium mt-3 text-black"> {{ $t('apps.campaigns.list.title') }}</h3>
            </header>            
            <div class="w-1/2  text-right">                
                <vs-button 
                    @click="createNew()" 
                    class="rounded-lg xs:w-full sm:w-auto" >
                    {{ $t('apps.campaigns.form.createTitle') }}
                    
                </vs-button>
            </div>
        </div>
        <Datatable 
        :config="datatableConfig"
        :elements="campaignCategories"
        :stripe="true" 
        :sst="true"
        :draggable="true"
        @filter="handleFilter"
        @sort="sort"   
        @action="handleAction" 
        :draggableHeadTitle=draggableHeadTitle
        :deleteDialog="{title: $t('shops.delete'), text: $t('apps.campaings.delete_category_dialog')}"
        />
        <div v-if="isModalOpen">
            <CreateOrEditCategoryForm
            :selectedCategory = selectedCategory
            :languages = languages
            :isNew = isNew
            :aplicationUuid = aplicationUuid
            :isModalOpen=isModalOpen
            @modalToFalse = modalToFalse()
            @updateList = updateList()
            ></CreateOrEditCategoryForm>
        </div>
        
    </div>

</template>


<script>

import CampaignCategoriesService from "@/modules/Apps/Services/CampaignCategoriesService";
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import { getPublishedOptions } from "@/modules/Apps/Helpers/campaignHelper"
import  CreateOrEditCategoryForm  from "@/modules/Apps/Components/campaigns/form/CreateOrEditCategoryForm"
import { v4 as uuidv4 } from 'uuid';
import {DateTime} from 'luxon';
import loader from "@/modules/Shared/Mixins/loader";
import router from "@/router";
import {mapGetters} from "vuex";



export default {    
    name: 'CampaignList',
    components: {
        Datatable,
        CreateOrEditCategoryForm
    },
    mixins: [loader],    
    data() {
        return {
            draggableHeadTitle:this.$t('apps.campaigns.list.orderTittle'),
            isNew:true,
            isModalOpen:false,
            selectedCategory: {},
            aplicationUuid: router.currentRoute.params.uuid,
            campaignCategories: [], 
            currentFilters: [],
            datatableConfig: [         
                { 'field': 'reference', head: this.$t('apps.campaigns.form.referenceTitle') , 'sortable': true ,'searchable': true},
                { 'field': 'published_at', "type": 'html', head:this.$t('apps.campaigns.list.datatable.published_col') , 'sortable': true ,'searchable' : true, 'searchableoptions':getPublishedOptions()},                
                { 'field': 'createdAt', head: this.$t('apps.campaigns.list.datatable.createdat_col') },                
                { 'field': 'actions', "type": 'actions', head: this.$t('apps.campaigns.list.datatable.action_col'),  'sortable': false },
            ],
        };
    },
    computed: {
        ...mapGetters('apps', [           
            'languages'
        ]),
    },
    async created() { 
        this.objectPerPage = 30
        
        this.baseFilters = [
            {
                field: 'application_uuid',
                value: router.currentRoute.params.uuid
            },
            
            {
                field: 'published_at',
                value: 'all'
            }
        ]
        this.currentFilters = this.baseFilters       
        this.getCampaignsCategories({})
    },
    
    methods:{ 
        async updateList(){
            await this.getCampaignsCategories()
        },
        modalToFalse(){
            this.isModalOpen = false
        } ,
        async getCampaignsCategories() {   
            const campaignsCategoriesData = await CampaignCategoriesService.getCampaignsCategories(this.currentFilters,  this.objectPerPage)            
            
            this.campaignCategories = campaignsCategoriesData.data.map(this.categoriesToTableRow)
        },
        categoriesToTableRow(category){
            return {
                uuid: category.uuid,
                reference: category.reference,
                published_at: category.published ? 
                `<div class="border border-success text-success py-2 px-4 rounded-full">${this.$t('apps.campaigns.list.datatable.published')}</div>`                 
                :`<div class="border border-warning text-warning py-2 px-4 rounded-full">${this.$t('apps.campaigns.list.datatable.unpublished')}</div>` ,
                createdAt: this.toCorrectDateFormatFromTimestamp( category.createdAt ),
                published: category.published == null ? false:true ,
                'actions' : ['delete','edit'],
                locales:category.locales
            }
        },
        
        toCorrectDateFormatFromTimestamp(timestamp){  
            return DateTime.fromSeconds(timestamp).toFormat('dd/MM/yyyy')
        },
        async handleFilter({page, filters}) {
            this.currentFilters = [...this.baseFilters, ...filters]
            this.currentPage = page;
            await this.getCampaignsCategories()
        },
        sort(categoriesOrdered) {       
            CampaignCategoriesService.sortCategories(router.currentRoute.params.uuid, categoriesOrdered)
        },
        async handleAction(eventType, uuid, __element) {
            
            switch (eventType) {
               
                case 'edit': { 
                    this.isNew = false
                    this.selectedCategory = __element
                    this.selectedCategory.uuid = uuid
                    this.isModalOpen = true
                    break
                }
                case 'delete': {
                    await this.load(async () => {
                        await CampaignCategoriesService.deleteCategory(uuid,router.currentRoute.params.uuid)
                        this.campaignCategories = this.campaignCategories.filter(campaignCategory => campaignCategory.uuid !== uuid)
                    });
                    break
                }                       
            }
        },
        createNew(){
            
            this.isNew = true
            this.selectedCategory = { 
                uuid: uuidv4(),
                reference: "",
                published:false,
                locales:{}
            },
            this.isModalOpen = true
        }
        
    }    
}
</script>

